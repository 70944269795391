import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addContributer } from "../list/listActions"
import { searchUser, selectUserEntities } from "../users/usersSlice"
import { Avatar, Button, Container, Divider, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@material-ui/core"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import { Person } from "@material-ui/icons"

export const AddContributerForm = (props: { contributers: string[]; listId: string; userId: string }) => {
	const { userId, listId, contributers } = props

	const userOwnsList = userId === sessionStorage.getItem("userId")

	const dispatch = useDispatch()

	const [userNameSearch, setUserNameSearch] = useState("")
	const [suggestions, setSuggestions] = useState<any[]>()
	const users = useSelector((state: any) => selectUserEntities(state))

	useEffect(() => {
		if (users) {
			if (userNameSearch.length <= 1) {
				setSuggestions([])
			} else {
				const storedUserNamesList = Object.keys(users)
					.map((user: string) => users[user]?.username?.toLowerCase().includes(userNameSearch.toLowerCase()) && users[user])
					.map((user) => (contributers?.includes(user.id) ? { ...user, alreadyOnList: true } : user))
					.filter((user) => !!user)

				setSuggestions(storedUserNamesList)
			}
		}
	}, [users, contributers, userNameSearch])

	const onNameChanged = (e: any) => {
		setUserNameSearch(e.target.value)
		if (e.target.value.length > 2) {
			dispatch(searchUser(e.target.value))
		}
	}

	const addContributerClicked = (contributer: string) => {
		try {
			dispatch(addContributer({ list: { userId, listId }, contributer, contributers }))
			setUserNameSearch("")
		} catch (err) {
			console.error("Failed to add user ", err)
		}
	}

	if (!userOwnsList) return <></>

	return (
		<Container style={{ marginBottom: 30, marginTop: 20 }}>
			{/* <Card style={{ marginBottom: 12, marginTop: 20 }}>
				<CardContent> */}
			<Typography variant="h5" component="h2" style={{ paddingBottom: 12 }}>
				<PersonAddIcon /> Add Contributer
			</Typography>
			<form noValidate autoComplete="off">
				<TextField
					label="Search Users by Name"
					autoComplete="off"
					fullWidth
					id="userName"
					name="userName"
					placeholder="YourFriendsCoolNickname"
					value={userNameSearch}
					onChange={onNameChanged}
				/>
			</form>

			<List>
				{suggestions?.map((user: any, index: number) => (
					<>
						<ListItem id={user.id} key={user.id}>
							<ListItemAvatar>
								<Avatar>
									<Person />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={user.username} secondary={`ID: ${user.id.substring(0,8)}`} onClick={() => addContributerClicked(user.id)} />
							<Button
								disableElevation
								variant="contained"
								size="small"
								onClick={() => addContributerClicked(user.id)}
								id={user.id}
								disabled={!!user.alreadyOnList}
							>
								Add
							</Button>
						</ListItem>
						{index+1 < suggestions.length ? <Divider /> : ""}
					</>
				))}
				{userNameSearch.length && !suggestions?.length ? (
					<ListItem>
						<ListItemAvatar>
							<Avatar>{/* <Person /> */}</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={"No user found for that query, yet..."}
							secondary={userNameSearch.length < 3 ? "Try typing at least three letters" : ""}
						/>
					</ListItem>
				) : (
					""
				)}
			</List>
			{/* </CardContent>
			</Card> */}
		</Container>
	)
}
