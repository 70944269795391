import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import { client } from "../../api/client"
import { isAuthenticated } from "../auth/autentication"
import { addItem, addContributer, moveToHistory, removeContributer, deleteItem, deleteItemFromHistory } from "../list/listActions"
import { getUserName, updateUserMe } from "../users/usersSlice"
import { List, ListIdentifier } from "./userView"
import { useDispatch } from "react-redux"


const listsAdapter = createEntityAdapter({
	sortComparer: (a: any, b: any) => b.created.localeCompare(a.created)
})

export const { selectAll: selectAllLists, selectById, selectIds } = listsAdapter.getSelectors((state: any) => state.lists)

export const getOwnInfoAndLists = createAsyncThunk("lists/getOwnInfoAndLists", async (userId: string, thunkAPI) => {
	const url = `${process.env.REACT_APP_API_ENDPOINT}/${userId}`
	const response: any = await client.get(url)
	if (response.status === 404) {
		return (window.location.pathname = "/username")
	}
	thunkAPI.dispatch(getUserName(userId))
	const externalLists = response.user.contributesTo
	externalLists.forEach((list: any) => thunkAPI.dispatch(getList({ userId: list.owner, listId: list.id })))
	thunkAPI.dispatch(updateUserMe(response))
	return response
})

export const getList = createAsyncThunk(
	"lists/getList",
	async (list: ListIdentifier): Promise<List> => {
		if (!isAuthenticated()) throw new Error("Missing Authentication")
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}`
		const response = await client.get(url)
		return response
	}
)

export const receiveListFromWebSocket = createAsyncThunk(
	"lists/receiveListFromWebSocket",
	(list: List): List => {
		return list;
	}
)

export const addNewList = createAsyncThunk(
	"lists/addNewList",
	async (listName: string): Promise<List[]> => {
		const userId = sessionStorage.getItem("userId")
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${userId}/list-stub`
		const response = await client.put(url, { listName: listName.trim() })
		return [
			{
				items: [],
				id: response.id,
				name: listName.trim(),
				contributers: [userId as string],
				created: Date.now().toString(),
				owner: userId || ""
			}
		]
	}
)

export const deleteList = createAsyncThunk("lists/deleteList", async (listId: string) => {
	const userId = sessionStorage.getItem("userId")
	const url = `${process.env.REACT_APP_API_ENDPOINT}/${userId}/${listId}`
	await client.delete(url)
	return listId
})

export const unsubscribeMeFromList = createAsyncThunk("lists/unsubscribeList", async (list: ListIdentifier) => {
	const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}`
	await client.patch(url, {
		contributer: sessionStorage.getItem("userId")
	})
	return list.listId
})

const initialState: any = listsAdapter.getInitialState({
	status: "idle",
	getListStatus: "idle",
	addNewListStatus: "idle",
	error: null
})

const listsSlice: any = createSlice({
	name: "lists",
	initialState,
	reducers: {},
	extraReducers: {
		[getOwnInfoAndLists.pending.toString()]: (state, action) => {
			state.status = "pending"
		},
		[getOwnInfoAndLists.fulfilled.toString()]: (state, action) => {
			listsAdapter.upsertMany(state, action.payload.ownLists)
			state.status = "fulfilled"
		},
		[getOwnInfoAndLists.rejected.toString()]: (state, action) => {
			state.status = "rejected"
			state.error = action.payload
		},
		[getList.pending.toString()]: (state, action) => {
			state.getListStatus = "pending"
		},
		[getList.rejected.toString()]: (state, action) => {
			// throw an error snackbar
			state.getListStatus = "rejected"
		},
		[getList.fulfilled.toString()]: (state, action) => {
			state.getListStatus = "fulfilled"
			listsAdapter.upsertMany(state, [action.payload])
		},
		[receiveListFromWebSocket.fulfilled.toString()]: (state, action) => {
			listsAdapter.upsertMany(state, [action.payload])
		},
		[addItem.pending.toString()]: (state, action) => {
			state.entities[action.meta.arg.list.listId].items.push({ name: action.meta.arg.item.name, inSync: true })
		},
		[addItem.fulfilled.toString()]: (state, action) => {
			listsAdapter.upsertMany(state, [action.payload])
		},
		[addItem.rejected.toString()]: (state, action) => {
			// throw an error snackbar
			state.entities[action.meta.arg.list.listId].items = state.entities[action.meta.arg.list.listId].items.filter(
				(item: { name: string }) => item.name !== action.meta.arg.item.name
			)
		},
		[deleteItemFromHistory.fulfilled.toString()]: (state, action) => {
			listsAdapter.upsertMany(state, [action.payload])
		},
		[deleteItem.fulfilled.toString()]: (state, action) => {
			listsAdapter.upsertMany(state, [action.payload])
		},
		[addNewList.fulfilled.toString()]: (state, action) => {
			listsAdapter.upsertMany(state, action.payload)
		},
		[addNewList.rejected.toString()]: (state, action) => {
			// throw an error snackbar
			state.addNewListStatus = "rejected"
		},
		[moveToHistory.fulfilled.toString()]: (state, action) => {
			listsAdapter.upsertMany(state, [action.payload])
		},
		[moveToHistory.pending.toString()]: (state, action) => {
			state.entities[action.meta.arg.list.listId].history.push(action.meta.arg.item.name)
			state.entities[action.meta.arg.list.listId].items = state.entities[action.meta.arg.list.listId].items.filter(
				(item: { name: string }) => item.name !== action.meta.arg.item.name
			)
		},
		[addContributer.fulfilled.toString()]: (state, action) => {
			listsAdapter.updateOne(state, action.payload)
		},
		[deleteList.fulfilled.toString()]: (state, action) => {
			listsAdapter.removeOne(state, action.payload)
		},
		[unsubscribeMeFromList.fulfilled.toString()]: (state, action) => {
			listsAdapter.removeOne(state, action.payload)
		},
		[removeContributer.fulfilled.toString()]: (state, action) => {
			listsAdapter.updateOne(state, action.payload)
		}
	}
})

export default listsSlice.reducer
