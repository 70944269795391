import { Button, Card, CardContent, Container, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getUserName, selectUserById, setCognitoUserName, setUserName } from "./usersSlice"
import EditIcon from "@material-ui/icons/Edit"
import { getOwnInfoAndLists } from "../lists/listsSlice"

export const InitialUsernameForm = () => {
	const userId = sessionStorage.getItem("userId")?.toString() as string

	const me = useSelector((state: any) => state.users.myself)
	const [name, setName] = useState(me?.name || "")
	const [addRequestStatus, setAddRequestStatus] = useState("idle")

	let history = useHistory()
	const dispatch = useDispatch()

	const onNameChanged = (e: any) => setName(e.target.value.trim())

	const canSave = name.length > 3 && addRequestStatus === "idle"

	const onSubmit = async (e: any) => {
		e.preventDefault()
		if (canSave) {
			try {
				setAddRequestStatus("pending")
				await ((dispatch(setUserName(name)) as unknown) as Promise<any>).then(() => {

					console.log("dkjfsl");
					const target: any = JSON.parse(sessionStorage.getItem("from") as string)
					if (target) {
						history.replace(target)
					} else {
						history.replace({ pathname: `${userId}` })
					}
				})
			} catch (err) {
				// TODO: snackbar
				console.error("Failed to set user name: ", err)
			} finally {
				setAddRequestStatus("idle")
			}
		}
	}

	return (
		<>
			<Container>
				<br />
				<Card>
					<CardContent>
						<h2>
							<EditIcon /> Choose a username
						</h2>
						<p>Others can find you by your username to add you as contributer to their lists.</p>
						<form onSubmit={onSubmit}>
							<TextField
								autoComplete="off"
								type="text"
								label="User Name"
								fullWidth
								id="UserName" 
								name="UserName"
								placeholder="MyCoolUserName77"
								value={name}
								onChange={onNameChanged}
							/>
							<br />
							<br />
							<Button variant="contained" type="button" onClick={onSubmit} disabled={!canSave}>
								Set User Name
							</Button>
						</form>
					</CardContent>
				</Card>
			</Container>
		</>
	)
}

export const UsernameForm = () => {
	const userId = sessionStorage.getItem("userId")?.toString() as string

	const me = useSelector((state: any) => state.users.myself)
	const [name, setName] = useState(me?.name)
	const [addRequestStatus, setAddRequestStatus] = useState("idle")

	const dispatch = useDispatch()

	useEffect(() => {
		if (userId && !me?.name) {
			dispatch(getUserName(userId))
		}
		setName(me?.name)
	}, [dispatch, me, userId])

	const onNameChanged = (e: any) => setName(e.target.value)

	const canSave = name?.length > 3 && addRequestStatus === "idle"

	const onSubmit = async (e: any) => {
		e.preventDefault()
		if (canSave) {
			try {
				setAddRequestStatus("pending")
				const sanitiziedName = name.trim()

				if(sanitiziedName === me?.name) {
					return alert(`Your username already is "${sanitiziedName}".`)
				}

				await ((dispatch(setCognitoUserName(sanitiziedName)) as unknown) as Promise<any>).then((data) => {
					if (data.error) {
						return alert(`Failed to set user name: ${JSON.stringify(data.error)}`)
					}
					alert(`Changed username to "${sanitiziedName}" successfully!`)
				})
			} finally {
				setAddRequestStatus("idle")
			}
		}
	}

	return (
		<>
			<Container>
				<br />
				<Card>
					<CardContent>
						<h2>
							<EditIcon /> Change username
						</h2>
						<p>Others can find you by your username to add you as contributer to their lists.</p>
						<form onSubmit={onSubmit}>
							<TextField
								autoComplete="off"
								type="text"
								label={""}
								fullWidth
								id="UserName"
								name="UserName"
								placeholder={"User Name"}
								value={name}
								onChange={onNameChanged}
							/>
							<br />
							<br />
							<Button variant="contained" type="button" onClick={onSubmit} disabled={!canSave}>
								Set User Name
							</Button>
						</form>
					</CardContent>
				</Card>
			</Container>
		</>
	)
}
