import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import { client } from "../../api/client"

export const usersAdapter = createEntityAdapter({
	sortComparer: (a: any, b: any) => b.username.localeCompare(a.username)
})

export const { selectAll, selectById: selectUserById, selectIds: selectUsersByIds, selectEntities: selectUserEntities } = usersAdapter.getSelectors(
	(state: any) => state.users
)
const userId = sessionStorage.getItem("userId") as string

export const setCognitoUserName = createAsyncThunk("users/setCognitoUserName", async (username: string) => {
	const urlCognito = `${process.env.REACT_APP_API_ENDPOINT}/users/user`
	await client.put(urlCognito, { username })
	return { username }
})

export const setUserName = createAsyncThunk("users/setUserName", async (username: string, thunkAPI) => {
	const urlAPI = `${process.env.REACT_APP_API_ENDPOINT}/${userId}`
	await thunkAPI.dispatch(setCognitoUserName(username))
	const response = await client.put(urlAPI, { username })
	return response
})

export const getUserName = createAsyncThunk("users/getUserName", async (id: string) => {
	const url = `${process.env.REACT_APP_API_ENDPOINT}/users/user?id=${id}`
	const response = await client.get(url)
	return response
})

export const searchUser = createAsyncThunk("users/searchUser", async (name: string) => {
	const url = `${process.env.REACT_APP_API_ENDPOINT}/users/${name}`
	const response = await client.get(url)
	return response
})

export const deleteUser = createAsyncThunk("users/deleteUser", async (userId: string) => {
	const url = `${process.env.REACT_APP_API_ENDPOINT}/${userId}`
	const response = await client.delete(url)
	return response
})

const initialState: any = usersAdapter.getInitialState({
	status: "idle",
	error: null
})

const usersSlice: any = createSlice({
	name: "users",
	initialState,
	reducers: {
		updateUserMe(state, action) {
			state.status = "fulfilled"
			state.myself = { ...action.payload.user }
			state.myself.hasOwnLists = !!action.payload.ownLists.length
			//state.entities[userId].contributesTo = action.payload.contributesTo
		}
	},
	extraReducers: {
		[getUserName.pending.toString()]: (state, action) => {
			state.status = "pending"
		},
		[getUserName.fulfilled.toString()]: (state, action) => {
			state.status = "fulfilled"
			if (action.payload.users[0]?.id === userId) {
				if (state.myself) {
					state.myself.name = action.payload.users[0].username
				}
			}
			usersAdapter.upsertMany(state, action.payload.users)
		},
		[setCognitoUserName.fulfilled.toString()]: (state, action) => {
			if (state.myself) {
				state.myself.name = action.payload.username
				state.entities[userId].username = action.payload.username
			}
		},
		[searchUser.fulfilled.toString()]: (state, action) => {
			usersAdapter.upsertMany(state, action.payload.users)
		}
	}
})

export const { updateUserMe } = usersSlice.actions

export default usersSlice.reducer
