import {
	Button,
	Card,
	CardActions,
	CardContent,
	CircularProgress,
	Container,
	Dialog,
	DialogTitle,
	Divider,
	List,
	Typography
} from "@material-ui/core"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { getUserName, selectUserById } from "../users/usersSlice"
import { AddListForm } from "./addList"
import { getOwnInfoAndLists, selectAllLists, deleteList, unsubscribeMeFromList } from "./listsSlice"
import { AppBarTop } from "../navigation/appbar"

export interface OwnInfoAndLists {
	user: {
		id: string
		joinedDate: string
		contributesTo: ListIdentifier[]
	}
	ownLists: List[]
}

export interface ListIdentifier {
	listId: string
	userId: string
	listName?: string
}

export interface List {
	id: string
	owner: string
	created: string
	name: string
	items?: any[]
	contributers?: string[]
	history?: string[]
}

let userId: string | null

export const UserPageListOverview = (props: { id: string }) => {
	const dispatch = useDispatch()
	const lists = useSelector(selectAllLists)
	const listsStatus = useSelector((state: any) => state.lists.status)
	const usersStatus = useSelector((state: any) => state.users.status)
	const user = useSelector((state: any) => selectUserById(state, props.id))

	userId = sessionStorage.getItem("userId")

	useEffect((): (() => void) => {
		if (user) {
			document.title = `${user?.username}'s lists`
		}
		return () => (document.title = `li5t.de`)
	})

	useEffect(() => {
		if (listsStatus === "idle") {
			userId = sessionStorage.getItem("userId")
			if (userId) {
				if (props.id === userId) {
					dispatch(getOwnInfoAndLists(userId))
				}
			}
		}
		if (!user && usersStatus === "idle") {
			dispatch(getUserName(props.id))
		}
	}, [listsStatus, usersStatus, user, dispatch, props.id])

	const deleteListButton = (list: List) => {
		const dispatchDeleteList = (e: any) => {
			if (list.contributers && list.contributers.length > 1) {
				return alert("Can not delete list that has contributers - remove them first")
			}
			const confirmation = window.confirm('Are you sure you want to delete "' + list.name + '" list?')

			if (confirmation) {
				dispatch(deleteList(e.target.id))
			}
		}

		if (list.owner === userId) {
			return (
				<span onClick={dispatchDeleteList} id={list.id}>
					Delete
				</span>
			)
		}
	}

	const unSubscribeFromListButton = (list: List) => {
		const dispatchUnsubscribeFromList = () => {
			const confirmation = window.confirm('Are you sure you want to unsubscribe from "' + list.name + '" list?')

			if (confirmation) {
				dispatch(unsubscribeMeFromList({ listId: list.id, userId: list.owner }))
			}
		}

		if (list.owner !== userId && list.contributers?.includes(userId as string)) {
			return (
				<span onClick={dispatchUnsubscribeFromList} id={list.id}>
					Unsubscribe
				</span>
			)
		}
	}

	let listArea

	if (listsStatus === "pending") {
		listArea = (
			<div style={{ margin: 12, textAlign: "center" }}>
				<CircularProgress color="secondary" />
			</div>
		)
	} else if (listsStatus === "fulfilled" && userId === props.id) {
		const listsListView = lists.map((list: any) => (
			<Card key={list.id} style={{ marginBottom: 12 }}>
				<CardContent>
					<Typography key={list.id + "Contributer"} color="textSecondary" gutterBottom>
						{(list?.contributers?.length).toString() + " contributers"}
					</Typography>
					<Typography key={list.id + "listName"} variant="h5" component="h2">
						<Link color="textPrimary" style={{ color: "black" }} to={`/${list.owner}/${list.id}`}>
							{list.name ? list.name : `Unnamed List (${list.id})`}
						</Link>
					</Typography>
					<Typography key={list.id + "itemCount"} color="textSecondary">
						{list?.items?.length + " items"}
					</Typography>
					<Typography key={list.id + "itemsPreview"} variant="body2" component="p" className="capitalize">
						{list.items
							?.slice(0, 5)
							.map((item: any) => item.name)
							.join(", ") + (list.items.length > 5 ? ", ..." : "")}
					</Typography>
				</CardContent>
				<CardActions>
					<Button disableElevation variant="contained" size="small">
						{deleteListButton(list)}
						{unSubscribeFromListButton(list)}
					</Button>
				</CardActions>
			</Card>
		))

		const ownListsArea = (
			<>
				<List>{listsListView}</List>
				<Divider />
			</>
		)

		listArea = ownListsArea
	} else if (listsStatus === "rejected") {
		listArea = (
			<Dialog open={true}>
				<DialogTitle id="alert-dialog-title">{"An Error Occured Retrieving Data"}</DialogTitle>
			</Dialog>
		)
	} else if (!user && usersStatus === "fulfilled") {
		listArea = (
			<Dialog open={true}>
				<DialogTitle id="alert-dialog-title">User does not exist.</DialogTitle>
			</Dialog>
		)
	}

	return (
		<>
			<AppBarTop title={user?.id === userId ? user?.username + "'s lists" : user ? "User: " + user?.username : ""}></AppBarTop>

			<Container>
				<>{listArea}</>
			</Container>
			<Container>
				<AddListForm />
			</Container>
		</>
	)
}
