import React, { useState } from "react"
import { ListIdentifier } from "../lists/userView"
import { client } from "../../api/client"
import { Button, Container, IconButton, TextField, Typography } from "@material-ui/core"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import FileCopyIcon from "@material-ui/icons/FileCopy"

export const CreateInvite = (props: ListIdentifier) => {
	const { userId, listId, listName } = props

	const [invite, setInvite] = useState("")
	const [duration, setDuration] = useState("")
	const [validUntil, setValidUntil] = useState("")

	const userOwnsList = userId === sessionStorage.getItem("userId")

	const onDurationChange = (e: any) => {
		setDuration(e.target.value)
	}

	const createInviteClicked = async (e: any) => {
		e.preventDefault()
		try {
			const url = `${process.env.REACT_APP_API_ENDPOINT}/token`
			const body = { validFor: parseInt(duration, 10) * 60 || undefined, list: listId }
			const response = await client.put(url, body)
			setInvite(`${window.location.origin}/invite/${response.inviteCode}`)
			setValidUntil(duration || "2880")
			setDuration("")
		} catch (err) {
			console.error("Failed to craete invite ", err)
		}
	}

	const copyToClipboard = async () => {
		await navigator.clipboard.writeText(
			`Join my list "${listName}" with this link: ${invite} The link will be valid for the next ${validUntil} minutes`
		)

		// TODO: show snackbar
	}

	if (!userOwnsList) return <></>

	return (
		<Container style={{ marginBottom: 30, marginTop: 20 }}>
			<Typography variant="h5" component="h2" style={{ paddingBottom: 12 }}>
				<ConfirmationNumberIcon /> Create Invitation Link
			</Typography>
			<form onSubmit={createInviteClicked} noValidate autoComplete="off">
				<TextField
					label="Valid for (Minutes)"
					autoComplete="off"
					fullWidth
					type="number"
					id="duration"
					name="duration"
					placeholder="60"
					value={duration}
					onChange={onDurationChange}
				/>
				<br />
				<br />
				<Button variant="contained" color="primary" type="submit">
					Create Invitation Link
				</Button>
			</form>

			{invite ? (
				<>
					<br />
					<TextField
						label="Invitation Text & Link"
						variant="filled"
						fullWidth
						multiline
						// change to a time until (instead of for minutes)
						value={`Join my list "${listName}" with this link: ${invite} The link will be valid for the next ${validUntil} minutes`}
					/>
					<br />
					<br />
					<Button variant="contained" color="primary" onClick={copyToClipboard} size="small" startIcon={<FileCopyIcon />}>
						Copy Invitation to Clipboard
					</Button>
				</>
			) : (
				""
			)}
		</Container>
	)
}
