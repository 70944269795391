import { Card, CardContent, Container, makeStyles } from "@material-ui/core"
import React from "react"
import { LoginButton } from "../auth/LoginPage"
import { Footer } from "./footer"

const useStyles = makeStyles({
	background: {
		background: "#fff url(./the-creative-exchange-ixS7UCRJTdM-unsplash.jpg)",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundColor: "black",
		filter: "blur(4px)",
		height: "100vh",
		width: "100vw",
		position: "fixed"
	},
	content: {
		zIndex: 2,
		position: "absolute",
		top: "0%",
		left: "0%",
		color: "black"
	}
})

export function HomePage() {
	const classes = useStyles()

	return (
		<>
			<div className={classes.background}></div>
			<Container className={classes.content}>
				<h5>li5t.de</h5>
				<br />
				<h1>Share shopping lists, securely.</h1>
				<h3> ✓ secure, encrypted, privacy first</h3>
				<h3> ✓ no tracking, no analytics, no ads</h3>
				<h3> ✓ free</h3>
				<br />
				<LoginButton />

				<Footer />
			</Container>
		</>
	)
}
