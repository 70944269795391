import Button from "@material-ui/core/Button"
import React from "react"
import { Link } from "react-router-dom"
import { isAuthenticated } from "../auth/autentication"

export function Footer() {
	if (!isAuthenticated() || ["/home"].includes(window.location.pathname)) {
		return (
			<div style={{ marginTop: 20 }}>
				<small style={{ display: "inline" }}>
					{!["/", "/home"].includes(window.location.pathname) ? (
						<>
							<Link to="/home"><Button>Home</Button></Link>{" "}
						</>
					) : (
						""
					)}
					<Link to="/legal"><Button>Legal</Button></Link> <Link to="/imprint"><Button>Imprint</Button></Link>
				</small>
			</div>
		)
	}
	return <></>
}
