import { Button, Card, CardContent, Container } from "@material-ui/core"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { deleteUser, selectUserById } from "./usersSlice"
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue"
import DeleteIcon from "@material-ui/icons/Delete"
import { getOwnInfoAndLists } from "../lists/listsSlice"
import { signout } from "../auth/autentication"

export const DeleteAccount = () => {
	const userId = sessionStorage.getItem("userId")?.toString() as string
	let history = useHistory()
	const dispatch = useDispatch()
	const user = useSelector((state: any) => selectUserById(state, userId))
	const me = useSelector((state: any) => state.users.myself)


	useEffect(() => {
		if (userId && !user) {
			dispatch(getOwnInfoAndLists(userId))
		}
	}, [dispatch, user, userId])

	const onSubmit = async (userId: string) => {
		if (me?.contributesTo?.length || me?.hasOwnLists) {
			return alert("Can't delete account with lists (active subscriptions or own lists). Delete or unsubscribe from all lists first.")
		}
		const confirmation = window.confirm("Are you sure you want to delete your account?")

		if (confirmation) {
			try {
				await (dispatch(deleteUser(userId) as unknown) as Promise<any>).then(() => {
					// delete all of users lists first
					// also delete user from cogntio user pool
					signout(() => {
						history.replace("/")
					})
				})
			} catch (err) {
				console.error("Failed to delete user: ", err)
			}
		}
	}

	return (
		<>
			<Container>
				<br />
				<Card>
					<CardContent>
						<h2>
							<RemoveFromQueueIcon /> Delete Account{user ? ": " + user.username : ""}
						</h2>
						<p>
							Sad to see you go! I appreaciate any feedback and compliants via <Link to="/feedback">this form</Link>.
						</p>
						<p>Please unsubscibe or delete your lists before deleting your account.</p>

						<br />
						<Button variant="contained" type="button" onClick={() => onSubmit(user.id)} startIcon={<DeleteIcon />}>
							Delete my Account
						</Button>
					</CardContent>
				</Card>
			</Container>
		</>
	)
}
