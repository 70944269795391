import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { authenticate, isAuthenticated, handleIdpCallback } from "./autentication"
import { useDispatch } from "react-redux"
import { getOwnInfoAndLists } from "../lists/listsSlice"
import { Button, Card, CardContent, CircularProgress, Container } from "@material-ui/core"
import { ExitToApp } from "@material-ui/icons"

export function ProgressScreen(props: { text: string }) {
	const { text } = props

	return (
		<Container>
			<br />
			<Card>
				<CardContent>
					<div style={{ margin: 12, textAlign: "center" }}>
						<CircularProgress color="secondary" />
						<p>{text}</p>
					</div>
				</CardContent>
			</Card>
		</Container>
	)
}

export default function LoginPage() {
	let location = useLocation()

	let { from }: any = location.state || { from: { pathname: "/" } }
	sessionStorage.setItem("from", JSON.stringify(from))

	if (from.pathname.includes("invite/")) {
		return (
			<Container>
				<br />

				<Card>
					<CardContent>
						<h1>You have been invited to contribute to a list!</h1>
						<p>Please register or login to accept the invitation</p>
						<LoginButton title="Proceed" />
					</CardContent>
				</Card>
			</Container>
		)
	} else {
		authenticate()
		return <ProgressScreen text="Logging you in..." />
	}
}

export function OauthCallbackPage() {
	let history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		handleIdpCallback(async () => {
			const target: any = JSON.parse(sessionStorage.getItem("from") as string)
			localStorage.setItem("hasBeenLoggedIn", JSON.stringify(true))
			const userId = sessionStorage.getItem("userId")

			if (userId) {
				// wait untill aciton has been finished
				await ((dispatch(getOwnInfoAndLists(userId)) as unknown) as Promise<any>).then(() => {
					console.log("Sending user to target: ", target);
					history.replace(target || "/")
				})
			} else {
				alert("Error")
			}
		})
	})

	return <ProgressScreen text="Logging you in..." />
}

export function LoginButton(props: { title?: string }) {
	const { title } = props
	const history = useHistory()

	if (!isAuthenticated()) {
		return (
			<Button variant="contained" color="secondary" onClick={authenticate} disableElevation startIcon={<ExitToApp />}>
				{title ? title : "Login"}
			</Button>
		)
	} else {
		return (
			<Button variant="contained" color="secondary" onClick={() => history.replace("/")}>
				Lists
			</Button>
		)
	}
}

// function LogoutButton() {
// 	let history = useHistory()

// 	const logout = () => {
// 		signout(() => {
// 			history.replace("/")
// 		})
// 	}

// 	if (isAuthenticated()) {
// 		return (
// 			<Button variant="contained" onClick={logout}>
// 				Logout
// 			</Button>
// 		)
// 	} else {
// 		return <></>
// 	}
// }
