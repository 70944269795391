import { Card, CardContent, Container } from "@material-ui/core"
import React from "react"
import { Footer } from "./footer"

export function Legal() {
	return (
		<Container>
			<br />
			<Card>
				<CardContent>
					<h1>Legal</h1>
					<p>Nutzungsbedingungen und Hinweise zum Datenschutz folgen.</p>
				</CardContent>
			</Card>
			<Footer />
		</Container>
	)
}
