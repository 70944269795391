import { Button, Card, CardContent, Container, Snackbar, TextField } from "@material-ui/core"
import React, { useState } from "react"
import { client } from "../../api/client"

export const MessageForm = () => {
	const [message, setMessage] = useState("")
	const [open, setOpen] = React.useState(false)
	const [id, setId] = React.useState("")

	const onMessageChange = (e: any) => setMessage(e.target.value)

	const canSave = message.length > 2 && message.length < 1000

	const onSubmit = async (e: any) => {
		e.preventDefault()
		if (canSave) {
			try {
				const url = `${process.env.REACT_APP_API_ENDPOINT}/message`
				const { id } = await client.post(url, { message })
				setId(id)
				setMessage("")
				setOpen(true)
			} catch (err) {
				alert("Failed to send message: " + JSON.stringify(err))
			}
		}
	}

	return (
		<Container>
			<br />
			<Card>
				<CardContent>
					<h2>Send a message</h2>
					<p>Please send any feature request, idea, complaint, bug report or anything you want us to know!</p>
					<form onSubmit={onSubmit}>
						<TextField
							label="Message text"
							multiline
							fullWidth
							rows={4}
							variant="outlined"
							autoComplete="off"
							type="text"
							id="message"
							name="message"
							placeholder="Your idea, complaint or bug report"
							value={message}
							onChange={onMessageChange}
						/>
						<br />
						<br />
						<Button variant="contained" color="primary" type="submit" onClick={onSubmit} disabled={!canSave}>
							Send Message
						</Button>
					</form>
					<Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} message={`Got your message! Message ID: ${id}`} />
				</CardContent>
			</Card>
		</Container>
	)
}
