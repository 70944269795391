import { configureStore } from '@reduxjs/toolkit'

import listsReducer from '../features/lists/listsSlice'
import usersReducer from '../features/users/usersSlice'

export default configureStore({
  reducer: {
    lists: listsReducer,
    users: usersReducer
  },
})
