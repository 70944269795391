import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { addItem } from "./listActions"

import * as sugItems from "./new-items.json"
import { Card, Grid, TextField } from "@material-ui/core"

const suggestedItems = sugItems.items;

export const AddItemForm = (props: { setSearchFieldIsUsed: (v: boolean) => any, listId: string; userId: string; items: string[]; recentItems: string[] }) => {
	const { userId, listId, items, recentItems, setSearchFieldIsUsed } = props

	const [name, setName] = useState("")
	const [suggestions, setSuggestions] = useState<string[]>([])
	const [addRequestStatus, setAddRequestStatus] = useState("idle")

	const dispatch = useDispatch()

	const onNameChanged = (e: any) => {
		setName(e.target.value)
		setSearchFieldIsUsed(!!e.target.value)

		if (e.target.value.length > 0) {
			setSuggestions(
				[
					...[...(suggestedItems.map(i => i.name)), ...(recentItems || [])].filter((item) => item.toLowerCase().includes(e.target.value.toLowerCase())),
					e.target.value
				]
					.filter((v, i, a) => a.map((w) => w.toLowerCase()).indexOf(v.toLowerCase()) === i)
					.filter((item) => !items.includes(item))
			)
		} else if (e.target.value.length === 0) {
			setSuggestions([])
		}
	}

	const isItemAlreadyOnList = items?.map((item: any) => item.name.toLowerCase().trim()).includes(name.toLowerCase().trim())

	const canSave = [name].every(Boolean) && addRequestStatus === "idle" && !isItemAlreadyOnList

	const saveItem = (name: string) => {
		try {
			setAddRequestStatus("pending")
			dispatch(addItem({ list: { userId, listId }, item: { name } }))
			setName("")
			setSuggestions([])
		} catch (err) {
			console.error("Failed to create the List: ", err)
		} finally {
			setAddRequestStatus("idle")
		}
	}

	const onSaveItemClicked = async (e: any) => {
		e.preventDefault()
		if (canSave) {
			saveItem(name)
		}
	}

	const onItemClicked = async (e: any) => {
		e.preventDefault()
		saveItem(e.target.id)
		setSuggestions([])
	}

	return (
		<section>
			<form autoComplete="off" onSubmit={onSaveItemClicked}>
				<label htmlFor="ItemName"></label>
				<TextField
					fullWidth
					label="Item Name"
					type="search"
					variant="outlined"
					helperText={isItemAlreadyOnList ? <small>{name.trim()} already on list</small> : ""}
					id="ItemName"
					name="ItemName"
					placeholder="Item name"
					value={name}
					onChange={onNameChanged}
				/>
			</form>
			<br />
			{suggestions.length ? <h3>Suggestions</h3> : ""}
			<Grid container spacing={1}>
				{suggestions?.map((item: string) => (
					<Grid item xs={4} sm={2} key={item}>
						<Card style={{ backgroundColor: "#e6ee9c	", textAlign: "center", borderRadius: 2 }} key={item} id={item} onClick={onItemClicked}>
							<span style={{ fontSize: 55, fontFamily: "'Londrina Outline', cursive" }} id={item}>
								{suggestedItems.find(i => i.name?.toLowerCase() === item.toLowerCase())?.icon ?? item.substring(0, 1).toUpperCase()}
							</span>
							<br />
							<span id={item} style={{ fontWeight: "bold" }} className={"capitalize"}>
								{item}
							</span>
							<br />
						</Card>
					</Grid>
				))}
			</Grid>
		</section>
	)
}
