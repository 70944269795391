import React, { useEffect, useState } from "react"
import { getList, receiveListFromWebSocket, selectById } from "../lists/listsSlice"
import { useSelector, useDispatch } from "react-redux"
import { List, ListIdentifier } from "../lists/userView"
import { AddItemForm } from "./addItem"
import { addItem, deleteItem, deleteItemFromHistory, moveToHistory } from "./listActions"
import { AddContributerForm } from "../contributers/contributerAddForm"
import { getUserName, selectUserEntities } from "../users/usersSlice"
import { CreateInvite } from "../contributers/createInvite"
import { Card, Container, Grid } from "@material-ui/core"
import { ContributersPopover, ContributerList } from "../contributers/contributersView"
import { AppBarTop } from "../navigation/appbar"
import { items as suggestedItems } from "./new-items.json"

let pollList: any
let socket: any;
const pollingInterval = 5
const pollingDuration = 120

function sortByName(a: { name: string }, b: { name: string }) {
	var nameA = a.name.toUpperCase() // ignore upper and lowercase
	var nameB = b.name.toUpperCase() // ignore upper and lowercase
	if (nameA < nameB) {
		return -1
	}
	if (nameA > nameB) {
		return 1
	}

	// names must be equal
	return 0
}

function EmptyListNote(props: any) {
	const { visible } = props

	if (visible) {
		return (
			<Container>
				<div style={{ textAlign: "center" }}>
					<h2>There are no items on this list, yet</h2>
					<p>Add items by typing an item name into the input field above</p>
				</div>
			</Container>
		)
	}

	return <></>
}

function CurrentItems(props: any) {
	const { uniqPlainItems, dispatchMoveToHistory, visible, removeItem } = props

	if (!visible) {
		return <></>
	}

	return (
		<Container>
			<h3>To get</h3>
			<Grid container spacing={1}>
				{uniqPlainItems?.map((item: { name: string; inSync: boolean }) => (
					<Grid item xs={4} sm={2} key={item.name}>
						<Card
							style={{ textAlign: "center", backgroundColor: item.inSync ? "#cfd8dc" : "#cfd8dc", borderRadius: 2 }}
							key={item.name}
							id={item.name}
							onClick={dispatchMoveToHistory}
						>
							<span style={{ fontSize: 55, fontFamily: "'Londrina Outline', cursive" }} id={item.name}>
								{suggestedItems.find(i => i.name?.toLowerCase() === item.name.toLowerCase())?.icon ?? item.name.substring(0, 1).toUpperCase()}
							</span>
							<br />
							<span id={item.name} style={{ fontWeight: "bold" }} className={"capitalize"}>
								{item.name}
							</span>
							<br />
						</Card>
						<span style={{ cursor: "pointer" }} onClick={() => removeItem(item.name)}>
							x
						</span>
					</Grid>
				))}
			</Grid>
		</Container>
	)
}

function RecentItems(props: any) {
	const { uniqFilteredHistory, addItemFromHistory, removeItemCompletelyFromHistory } = props

	if (!uniqFilteredHistory?.length) {
		return <></>
	}

	return (
		<Container>
			<h3>Recent</h3>
			<Grid container spacing={1}>
				{uniqFilteredHistory?.map((item: string) => (
					<Grid item xs={4} sm={2} key={item}>
						<Card
							style={{ textAlign: "center", backgroundColor: "#eeeeee", borderRadius: 2 }}
							key={item}
							onClick={() => addItemFromHistory(item)}
						>
							<span style={{ fontSize: 55, fontFamily: "'Londrina Outline', cursive" }} id={item}>
								{suggestedItems.find(i => i.name?.toLowerCase() === item.toLowerCase())?.icon ?? item.substring(0, 1).toUpperCase()}
							</span>
							<br />
							<span id={item} style={{ fontWeight: "bold" }} className={"capitalize"}>
								{item}
							</span>
							<br />
						</Card>
						<span style={{ cursor: "pointer" }} onClick={() => removeItemCompletelyFromHistory(item)}>
							x
						</span>
					</Grid>
				))}
			</Grid>
		</Container>
	)
}

export function ListView(props: ListIdentifier) {
	const { userId, listId } = props

	const dispatch = useDispatch()
	const list = useSelector((state: any) => selectById(state, listId))
	const getListStatus = useSelector((state: any) => state.lists.getListStatus)
	const users = useSelector((state: any) => selectUserEntities(state))
	// const [visibilityState, setVisibilityState] = useState("")
	const [searchFieldIsUsed, setSearchFieldIsUsed] = useState(false)
	const [wsSubscribed, setWsSubscribed] = useState(false)

	useEffect((): (() => void) => {
		if (!list && getListStatus === "idle") {
			dispatch(getList({ userId, listId }))
		}

		if (list) {
			document.title = `${list.name}`
			// document
			// 	.getElementById("favicon")
			// 	?.setAttribute("href", `favicon.ico`)
		}

		if (list?.items?.length) {
			document.title = `(${list.items.length}) ${list.name}`
			// document
			// 	.getElementById("favicon")
			// 	?.setAttribute("href", `https://calendar.google.com/googlecalendar/images/favicon_v2014_${list.items.length}.ico`)
		}

		return () => (document.title = `li5t.de`)
	}, [getListStatus, list, dispatch, listId, userId])

	const openWebSocket = (listId: string) => {
		const access_token = sessionStorage.getItem("access_token");
		const WS_GATEWAY_ID = "od914g0ai3";
		const wsUrl = `wss://${WS_GATEWAY_ID}.execute-api.eu-central-1.amazonaws.com/dev?owner=${list.owner}&list=${listId}`;
		socket = new WebSocket(wsUrl, access_token?.toString());

		window.onbeforeunload = () => {
			socket.close();
		};

		socket.onopen = () => {
			console.log('Web Socket Connection Opened');
			setWsSubscribed(true)
		};

		socket.onclose = () => {
			console.log('Web Socket Connection Closed');
			setWsSubscribed(false)
		};

		socket.onmessage = (event: any) => {
			const data = JSON.parse(event.data);
			if (data.id === listId) {
				dispatch(receiveListFromWebSocket(data));
			}
		};
	}

	const closeWebSocket = () => {
		socket.close()
	}

	useEffect(() => {
		if (list?.id && list?.owner && !wsSubscribed) {
			openWebSocket(list.id)
		}
	}, [list])

	useEffect(() => {
		return () => {
			closeWebSocket();
		}
	}, [])

	const contributers = list?.contributers
	const uniqPlainItems = list?.items
		.map((item: { name: string; inSync: boolean }) => ({ name: item.name.trim(), inSync: item.inSync }))
		.sort(sortByName)
	const filteredHistory = list?.history?.filter(
		(item: string) => !uniqPlainItems.map((i: { name: string }) => i.name.toLowerCase()).includes(item.toLowerCase())
	)
	const uniqFilteredHistory = Array.from(new Set(filteredHistory)) as string[]
	const storedUsersList = Object.keys(users)

	useEffect(() => {
		contributers?.forEach((contributer: string) => {
			if (!storedUsersList.includes(contributer)) {
				dispatch(getUserName(contributer))
			}
		})
	}, [contributers, dispatch, storedUsersList])

	// let hidden = "webkitHidden"
	// let visibilityChange = "webkitvisibilitychange"

	// if (typeof document.hidden !== "undefined") {
	// 	// Opera 12.10 and Firefox 18 and later support
	// 	hidden = "hidden"
	// 	visibilityChange = "visibilitychange"
	// } else if (typeof (document as any).msHidden !== "undefined") {
	// 	hidden = "msHidden"
	// 	visibilityChange = "msvisibilitychange"
	// }

	// if (visibilityChange && hidden) {
	// 	document.addEventListener(visibilityChange, handleVisibilityChange)
	// }

	// function handleVisibilityChange() {
	// 	if ((document as any)[hidden]) {
	// 		setVisibilityState("hidden")
	// 	} else {
	// 		setVisibilityState("visible")
	// 	}
	// }

	// const startPolling = (intervalInSeconds = 10) => {
	// 	if (pollList) {
	// 		clearInterval(pollList)
	// 	}
	// 	pollList = setInterval(() => dispatch(getList({ userId, listId })), intervalInSeconds * 1000)
	// 	stopPolling(pollingDuration)
	// }

	// const stopPolling = (inSeconds = 0) => {
	// 	if (pollList) {
	// 		setTimeout(() => clearInterval(pollList), inSeconds * 1000)
	// 	}
	// }

	// useEffect((): any => {
	// 	if (visibilityState === "hidden") {
	// 		clearInterval(pollList)
	// 	}

	// 	if (visibilityState === "visible" && list?.contributers?.length > 1) {
	// 		startPolling(5)
	// 		dispatch(getList({ userId, listId }))
	// 	}
	// }, [visibilityState])

	// useEffect(() => {
	// 	if (list?.contributers?.length > 1) {
	// 		startPolling(pollingInterval)
	// 	}
	// 	if (list?.contributers?.length <= 1) {
	// 		clearInterval(pollList)
	// 	}
	// }, [list?.contributers])

	const dispatchMoveToHistory = (e: any) => {
		dispatch(moveToHistory({ list: { userId, listId }, item: { name: e.target.id } }))
	}

	const addItemFromHistory = (name: string) => {
		if (!list.items.find((item: any) => item.name === name)) {
			dispatch(addItem({ list: { userId, listId }, item: { name } }))
		}
	}

	const removeItemCompletelyFromHistory = (name: string) => {
		dispatch(deleteItemFromHistory({ list: { userId, listId }, item: { name } }))
	}

	const removeItem = (name: string) => {
		dispatch(deleteItem({ list: { userId, listId }, item: { name } }))
	}

	if (getListStatus === "rejected") {
		// TODO: make a nice snackbar for this error
		return <>error</>
	}

	return (
		<>
			<AppBarTop title={list?.name}>
				<ContributersPopover contributers={contributers} list={list}>
					<ContributerList contributers={contributers} online={list?.online} list={list} users={users} />
					<AddContributerForm userId={userId} listId={listId} contributers={list?.contributers} />
					<CreateInvite listId={listId} userId={userId} listName={list?.name} />
				</ContributersPopover>
			</AppBarTop>

			<Container>
				<br />
				<AddItemForm
					setSearchFieldIsUsed={setSearchFieldIsUsed}
					userId={userId}
					listId={listId}
					items={uniqPlainItems}
					recentItems={uniqFilteredHistory}
				/>
			</Container>

			<EmptyListNote visible={!uniqPlainItems?.length && !uniqFilteredHistory?.length && !searchFieldIsUsed} />
			<CurrentItems
				uniqPlainItems={uniqPlainItems}
				dispatchMoveToHistory={dispatchMoveToHistory}
				removeItem={removeItem}
				visible={uniqPlainItems?.length || uniqFilteredHistory?.length}
			/>
			<RecentItems
				uniqFilteredHistory={uniqFilteredHistory}
				addItemFromHistory={addItemFromHistory}
				removeItemCompletelyFromHistory={removeItemCompletelyFromHistory}
			/>
		</>
	)
}
