import * as jose from "jose";
import { bufferToBase64UrlEncoded, getConfig, randomString, sha256 } from "./util"

const featureConfig = {
	useRefreshToken: false
}

interface TokenSet {
	access_token: string
	expires_in: number
	id_token: string
	refresh_token: string
	token_type: "Bearer"
}

export function isAuthenticated(): boolean {
	const tokenSet = sessionStorage.getItem("tokenSet")

	if (typeof tokenSet === "string") {
		const { access_token: accessToken }: TokenSet = JSON.parse(tokenSet)

		/** TODO: add cryptographic verification of jwt here, maybe */
		if (stillValid(accessToken)) {
			return true
		}
	}

	return false
}

export async function authenticate() {
	if (!sessionStorage.getItem("retrievalOngoing") && !isAuthenticated()) {
		if (featureConfig.useRefreshToken) {
			const isAuthenticated = await tryAuthentificationByRefreshToken()

			if (!isAuthenticated) {
				return await getCode()
			}
		}

		await getCode()
	}
}

export function signout(cb: any) {
	sessionStorage.removeItem("tokenSet")
	sessionStorage.removeItem("from")
	sessionStorage.removeItem("userId")
	localStorage.removeItem("refresh_token")
	cb()
}

export function stillValid(token: string | null, forAtLeast = 10): boolean {
	if (token === null) return false
	const decodedToken: { exp: number } = jose.decodeJwt(token) as {
		exp: number
	}

	return (decodedToken.exp || 0) - forAtLeast > Date.now() / 1000
}

async function tryAuthentificationByRefreshToken(): Promise<boolean> {
	if (sessionStorage.getItem("retrievalOngoing") !== "true") {
		const refreshToken = localStorage.getItem("refresh_token")

		if (typeof refreshToken === "string" && !isAuthenticated()) {
			sessionStorage.setItem("retrievalOngoing", "true")
			await refreshTokenSet(refreshToken)
		}
	}

	return isAuthenticated()
}

async function refreshTokenSet(refresh_token: string): Promise<void> {
	const config = await getConfig()

	// exchange the authorization code for a tokenset
	const tokenSet: TokenSet = await fetch(config.token_endpoint, {
		method: "POST",
		body: new URLSearchParams({
			client_id: process.env.REACT_APP_OAUTH_APP_CLIENT_ID as string,
			grant_type: "refresh_token",
			refresh_token
		}),
		headers: new Headers({
			"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
		})
	}).then((r) => r.json())

	sessionStorage.setItem("tokenSet", JSON.stringify(tokenSet))
	sessionStorage.removeItem("retrievalOngoing")

	/** forward to target */
	window.location.pathname = "oauth-callback"
}

async function getCode(): Promise<void> {
	try {
		const openidConfig = await getConfig(process.env.REACT_APP_OAUTH_DOMAIN)

		const state = randomString(32)
		const codeVerifier = randomString(32)
		const codeChallenge = await sha256(codeVerifier).then(bufferToBase64UrlEncoded)

		// we need to store the state to validate the callback
		// and also the code verifier to send later
		sessionStorage.setItem(`login-code-verifier-${state}`, codeVerifier)

		const authorizationEndpointUrl = new URL(openidConfig.authorization_endpoint)

		// here we encode the authorization request
		authorizationEndpointUrl.search = new URLSearchParams({
			redirect_uri: `${window.location.origin}/${process.env.REACT_APP_REDIRECT_URI_PATH}` as string,
			client_id: process.env.REACT_APP_OAUTH_APP_CLIENT_ID as string,
			response_type: "code",
			scope: "openid email profile aws.cognito.signin.user.admin",
			code_challenge: codeChallenge,
			code_challenge_method: "S256",
			state: state
		}).toString()

		window.location.assign(authorizationEndpointUrl.toString())
	} catch {
		sessionStorage.setItem("retrievalOngoing", "false")
	}
}

export async function handleIdpCallback(callback: () => void) {
	const search = new URLSearchParams(window.location.search)

	if (search.has("code")) {
		const code = search.get("code")
		const state = search.get("state")
		const code_verifier = sessionStorage.getItem(`login-code-verifier-${state}`)

		if (!code_verifier) {
			console.error("unexpected state parameter")
			return
		}

		//sessionStorage.has
		const config = await getConfig(process.env.REACT_APP_OAUTH_DOMAIN)

		// exchange the authorization code for a tokenset
		const tokenSet = await fetch(config.token_endpoint, {
			method: "POST",
			body: new URLSearchParams({
				client_id: process.env.REACT_APP_OAUTH_APP_CLIENT_ID,
				redirect_uri: `${window.location.origin}/${process.env.REACT_APP_REDIRECT_URI_PATH}`,
				grant_type: "authorization_code",
				code_verifier,
				code
			} as any),
			headers: new Headers({
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			})
		}).then((r) => r.json())

		const userId = jose.decodeJwt(tokenSet.id_token)?.sub
		// window[userId] = userId

		sessionStorage.setItem("tokenSet", JSON.stringify(tokenSet))
		localStorage.setItem("refresh_token", tokenSet.refresh_token)
		sessionStorage.setItem("access_token", tokenSet.access_token)
		sessionStorage.setItem("userId", userId as string)
		sessionStorage.removeItem("retrievalOngoing")

		//remove the querystring from the url in the address bar
		window.history.replaceState("", "", window.location.origin)
	}

	callback()
}
