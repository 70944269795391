// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(endpoint: string, { body, ...customConfig }: any = {}) {
	const headers = {
		"Content-Type": "application/json",
		Authorization: sessionStorage.getItem("access_token"),
		"X-API-Key": process.env.REACT_APP_API_KEY
	}

	const { method } = customConfig

	const config = {
		method: method || "GET",
		...customConfig,
		headers: {
			...headers
			// ...customConfig.headers
		}
	}

	if (body) {
		config.body = JSON.stringify(body)
	}

	let data
	try {
		const response = await window.fetch(endpoint, config)
		if (response.status === 204) return
		if (response.status === 404) return response
		data = await response.json()
		if (response.ok) {
			return data
		}
		throw new Error(response.statusText)
	} catch (err) {
		console.error(err.message)
		return Promise.reject(err.message ? err.message : data)
	}
}

client.get = function (endpoint: string, customConfig: any = {}) {
	return client(endpoint, { ...customConfig, method: "GET" })
}

client.post = function (endpoint: string, body: any, customConfig: any = {}) {
	return client(endpoint, { ...customConfig, body, method: "POST" })
}

client.put = function (endpoint: string, body: any, customConfig: any = {}) {
	return client(endpoint, { ...customConfig, body, method: "PUT" })
}

client.patch = function (endpoint: string, body: any = {}, customConfig: any = {}) {
	return client(endpoint, { ...customConfig, body, method: "PATCH" })
}

client.delete = function (endpoint: string) {
	return client(endpoint, {method: "DELETE" })
}