import {
	AppBar,
	createStyles,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Theme,
	Toolbar,
	Typography
} from "@material-ui/core"
import React from "react"
import { useState } from "react"
import MenuIcon from "@material-ui/icons/Menu"
import ListIcon from "@material-ui/icons/List"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import { useHistory } from "react-router-dom"
import { isAuthenticated, signout } from "../auth/autentication"
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1
		},
		menuButton: {
			marginRight: theme.spacing(2)
		},
		title: {
			flexGrow: 1,
			whiteSpace: "nowrap"
		}
	})
)

const mainMenu = [
	{ text: "Lists", path: "/", icon: <ListIcon /> },
	{ text: "Profile", path: "/profile", icon: <AccountCircleTwoToneIcon /> }
]
const metaMenu = [
	{ text: "Feedback", path: "/feedback", icon: <></> },
	{ text: "Imprint", path: "/imprint", icon: <></> },
	{ text: "Legal", path: "/legal", icon: <></> }
]

export function AppBarTop(props: any) {
	const { children, title } = props
	const [drawerOpen, setDrawerOpen] = useState(false)
	let history = useHistory()
	const classes = useStyles()

	if (!isAuthenticated()) {
		return <></>
	}

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Toolbar>
					<IconButton onClick={() => setDrawerOpen(true)} edge="start" color="inherit" className={classes.menuButton} aria-label="menu">
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{title}
					</Typography>
					{children}
					{/* <IconButton
						aria-label="go to account page of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={() => history.replace({ pathname: "/" })}
						color="inherit"
					>
						<AccountCircle />
					</IconButton> */}
				</Toolbar>
			</AppBar>
			<Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
				<List>
					{mainMenu.map((item) => (
						<ListItem button key={item.text} onClick={() => history.replace({ pathname: item.path })}>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.text} />
						</ListItem>
					))}
				</List>
				<Divider />
				<List>
					<ListItem button key={"logout"} onClick={() => signout(() => (window.location.pathname = "/"))}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItem>
				</List>
				<Divider />

				<List>
					{metaMenu.map((item) => (
						<ListItem button key={item.text} onClick={() => history.replace({ pathname: item.path })}>
							{/* <ListItemIcon>{item.icon}</ListItemIcon> */}
							<ListItemText primary={item.text} />
						</ListItem>
					))}
				</List>
			</Drawer>
		</div>
	)
}
