import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { addContributer } from "../list/listActions"
import { client } from "../../api/client"
import { useHistory } from "react-router-dom"
import { ProgressScreen } from "../auth/LoginPage"

export interface IvniteTokenResponse {
	owner: string
	list: string
	inviteToken: string
}

export const RedeemInvite = (props: { code: string }) => {
	const { code } = props
	const dispatch = useDispatch()
	const [status, setStatus] = useState("")
	let history = useHistory()

	const thisUserId = sessionStorage.getItem("userId") as string

	useEffect(() => {
		if (code && code.length === 8) {
			setStatus("Retrieving invitaion")
			getInviteTokenAndList(code).then(async (invite: IvniteTokenResponse) => {
				if (invite.owner === thisUserId) {
					// just send user to the list
					console.log("own list")
					return history.replace({ pathname: `/${invite.owner}/${invite.list}` })
				}
				setStatus("Subscribing to list")
				const event = {
					list: { listId: invite.list, userId: invite.owner },
					contributer: thisUserId,
					contributers: [],
					inviteToken: invite.inviteToken
				}
				await ((dispatch(addContributer(event)) as unknown) as Promise<any>).then(() => {
					setStatus("Forwarding to list")
					history.replace({ pathname: `/${invite.owner}/${invite.list}` })
				})
			})
		} else {
			setStatus("Not a valid invitation")
		}
	}, [code, thisUserId, dispatch, history])

	const getInviteTokenAndList = async (code: string): Promise<IvniteTokenResponse> => {
		const url = `${process.env.REACT_APP_API_ENDPOINT}/token`
		const body = { inviteCode: code }
		const response = await client.post(url, body)
		return response
	}

	return <ProgressScreen text={status} />
}
