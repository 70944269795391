import { createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../api/client"
import { List, ListIdentifier } from "../lists/userView"

export const addItem = createAsyncThunk(
	"lists/addItem",
	async (payload: { list: ListIdentifier; item: { name: string; amount?: number } }): Promise<List[]> => {
		const { list, item } = payload
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}/${item.name.toLowerCase()}`
		const response = await client.put(url, { amount: item.amount || 1 })
		return response
	}
)

export const deleteItem = createAsyncThunk(
	"lists/deleteItem",
	async (payload: { list: ListIdentifier; item: { name: string; amount?: number } }): Promise<List[]> => {
		const { list, item } = payload
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}/${item.name}`
		const response = await client.delete(url)
		return response
	}
)

export const deleteItemFromHistory = createAsyncThunk(
	"lists/deleteItemFromHistory",
	async (payload: { list: ListIdentifier; item: { name: string; amount?: number } }): Promise<List[]> => {
		const { list, item } = payload
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}/${item.name}/history`
		const response = await client.delete(url)
		return response
	}
)

export const moveToHistory = createAsyncThunk(
	"lists/moveToHistory",
	async (payload: { list: ListIdentifier; item: { name: string } }): Promise<List[]> => {
		const { list, item } = payload
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}/${item.name}`
		const response = await client.patch(url)
		return response
	}
)

export const removeContributer = createAsyncThunk(
	"lists/removeContributer",
	async (payload: { list: ListIdentifier; contributer: string; contributers: string[] }): Promise<any> => {
		const { list, contributers, contributer } = payload
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}`
		await client.patch(url, { contributer })
		return { id: list.listId, changes: { contributers: contributers.filter((v: string) => v !== contributer) } }
	}
)

export const addContributer = createAsyncThunk(
	"lists/addContributer",
	async (payload: { list: ListIdentifier; contributer: string; contributers: string[], inviteToken?: string }): Promise<any> => {
		const { list, contributers, contributer, inviteToken } = payload
		if (contributer.length !== 36) throw new Error("Contributer ID given is not a user ID")
		const url = `${process.env.REACT_APP_API_ENDPOINT}/${list.userId}/${list.listId}`
		await client.post(url, { contributer, inviteToken })
		return { id: list.listId, changes: { contributers: [...contributers, contributer] } }
	}
)
