import { Card, CardContent, Container } from "@material-ui/core"
import React from "react"
import { Footer } from "./footer"

export function Imprint() {
	return (
		<Container>
			<br />
			<Card>
				<CardContent>
					<h1>Imprint</h1>
					<p>
						Dies ist ein pirvates Projekt, das unverbindlich und kostenfrei angeboten wird, durch: Robert Schulz, zu erreichen per E-Mail
						an r@r0bs.net.
					</p>
					<p>
						Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehme ich keine Haftung für die Inhalte externer Links oder
						nutzergenerierter Inhalte. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Sollte
						aber klar sein!
					</p>
				</CardContent>
			</Card>
			<Footer />
		</Container>
	)
}
