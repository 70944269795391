import React from "react"
import "./App.css"
import { BrowserRouter as Router, Switch, Route, useParams, Redirect, useLocation } from "react-router-dom"
import LoginPage, { OauthCallbackPage } from "./features/auth/LoginPage"
import { isAuthenticated, stillValid } from "./features/auth/autentication"
import { UserPageListOverview } from "./features/lists/userView"
import { UsernameForm, InitialUsernameForm } from "./features/users/username"
import { ListView } from "./features/list/listView"
import { RedeemInvite } from "./features/contributers/redeemInvite"
import { MessageForm } from "./features/message/message"
import { HomePage } from "./features/pages/home"
import { Legal } from "./features/pages/legal"
import { Imprint } from "./features/pages/imprint"
import { DeleteAccount } from "./features/users/deleteAccount"
import { AppBarTop } from "./features/navigation/appbar"
import { ProfileHeader } from "./features/users/profileHeader"

export default function NestingExample() {
	return (
		<>
			<Router>
				<div>
					<Switch>
						<Route exact path="/">
							<User />
						</Route>

						<Route exact path="/home">
							<HomePage />
						</Route>

						<Route path="/login">
							<LoginPage />
						</Route>

						<Route path="/imprint">
							<AppBarTop title="Imprint"></AppBarTop>

							<Imprint />
						</Route>

						<Route path="/legal">
							<AppBarTop title="Legal"></AppBarTop>

							<Legal />
						</Route>

						<Route path="/oauth-callback">
							<OauthCallbackPage />
						</Route>

						<Route path={["/invite/:code", "/invite"]}>
							<Invite />
						</Route>

						<PrivateRoute path="/username">
							<AppBarTop title="Username"></AppBarTop>

							<InitialUsernameForm />
						</PrivateRoute>

						<PrivateRoute path={["/settings", "/profile"]}>
							<AppBarTop title="Profile"></AppBarTop>

							<ProfileHeader />
							<UsernameForm />
							<DeleteAccount />
						</PrivateRoute>

						<PrivateRoute path="/feedback">
							<AppBarTop title="Feedback"></AppBarTop>

							<MessageForm />
						</PrivateRoute>

						<PrivateRoute path={`/:userId/:listId`}>
							<List />
						</PrivateRoute>

						<PrivateRoute path={["me", "mylists", "lists", `/:userId`]}>
							<User />
						</PrivateRoute>
					</Switch>
				</div>
			</Router>
		</>
	)
}

function User() {
	let { userId }: { userId: string } = useParams()

	if (["/", "/home", "/me", "/mylists", "/lists"].includes(window.location.pathname)) {
		userId = sessionStorage.getItem("userId") as string
		const access_token = sessionStorage.getItem("access_token") as string
		const accessValid = stillValid(access_token)

		if (userId && accessValid) {
			return (
				<Redirect
					to={{
						pathname: `/${userId}`
					}}
				/>
			)
		}

		return (
			<Redirect
				to={{
					pathname: `/home`
				}}
			/>
		)
	}

	return <UserPageListOverview id={userId} />
}

function List() {
	let { userId, listId }: any = useParams()
	return <ListView userId={userId} listId={listId} />
}

function Invite() {
	let { code }: any = useParams()
	const location = useLocation()

	if (!isAuthenticated()) {
		return (
			<Redirect
				to={{
					pathname: "/login",
					state: { from: location }
				}}
			/>
		)
	}
	return <RedeemInvite code={code} />
}

function PrivateRoute({ children, ...rest }: { children: any; path: any }) {
	// eslint-disable-next-line
	const location = useLocation()

	return (
		<Route
			{...rest}
			render={({ location }) => {
				if (!isAuthenticated()) {
					return (
						<Redirect
							to={{
								pathname: "/",
								state: { from: location }
							}}
						/>
					)
				} else {
					return children
				}
			}}
		/>
	)
}
