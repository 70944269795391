import { Container, makeStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectUserById } from "./usersSlice"
import { getOwnInfoAndLists } from "../lists/listsSlice"
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';

const useStyles = makeStyles({
	ProfileHeader: {
		textAlign: "center"
	}
})

export const ProfileHeader = () => {
	const classes = useStyles()
	const userId = sessionStorage.getItem("userId")?.toString() as string
	const dispatch = useDispatch()
	const me = useSelector((state: any) => state.users.myself)

	useEffect(() => {
		if (userId && !me) {
			dispatch(getOwnInfoAndLists(userId))
		}
	}, [dispatch, me, userId])

	return (
		<>
			<Container className={classes.ProfileHeader}>
				<br />
				<AccountCircleTwoToneIcon style={{ fontSize: 200 }} />
                <h1>{me?.name}</h1>
                <h2>User-ID: {me?.id.substring(0,8)}</h2>
			</Container>
		</>
	)
}
