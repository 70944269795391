import {
	AppBar,
	Avatar,
	Badge,
	Button,
	Chip,
	Container,
	createStyles,
	Dialog,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Slide,
	Theme,
	Toolbar,
	Typography
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { AccountCircle, Close, People } from "@material-ui/icons"
import React from "react"
import { useDispatch } from "react-redux"
import { removeContributer } from "../list/listActions"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			position: "relative"
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1
		}
	})
)

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) {
	return <Slide direction="up" ref={ref} {...props} />
})

export function ContributersPopover(props: any) {
	const { contributers, children, list } = props
	const classes = useStyles()

	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<Chip avatar={<Avatar>{contributers?.length}</Avatar>} variant="outlined" label="Contributers" onClick={handleClickOpen} />

			{/* <IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleClickOpen}
				color="inherit"
			>
				<Badge badgeContent={contributers?.length} color="secondary">
					<PeopleAlt />
				</Badge>
			</IconButton> */}
			<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<Close />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{list?.name}: Contributers
						</Typography>
					</Toolbar>
				</AppBar>
				<Container>{children}</Container>
			</Dialog>
		</>
	)
}

export const ContributerList = (props: { contributers: any[]; online: any[]; list: any; users: any }) => {
	const { contributers, list, users, online } = props
	const me = sessionStorage.getItem("userId") as string

	const dispatch = useDispatch()

	const RemoveContributerButton = (contributer: string) => {
		const dispatchRemoveContributer = (contributer: string) => {
			const confirmation = window.confirm("Are you sure you want to remove this contributer from the list?")

			if (confirmation) {
				dispatch(removeContributer({ list: { listId: list.id, userId: list.owner }, contributer, contributers: list.contributers }))
			}
		}

		if (list.owner === me && contributer !== me) {
			return (
				<Button disableElevation variant="contained" size={"small"} onClick={() => dispatchRemoveContributer(contributer)} id={contributer}>
					Remove
				</Button>
			)
		}
	}

	if (contributers.length < 1) {
		return <></>
	}

	return (
		<Container style={{ marginBottom: 30, marginTop: 20 }}>

			<Typography variant="h5" component="h2">
				<People /> Contributers
			</Typography>
			<List>
				{contributers?.map((contributer: string) => {
					const { username, id } = users[contributer] || {}
					const ownerIndicator =  list.owner === id ? " (Owner)" : "";
					const userOnline = online?.find(user => user.user === contributer) ? <Badge color="secondary" variant="dot">{username + ownerIndicator}</Badge> : username + ownerIndicator;
					return (
						<ListItem id={contributer} key={contributer}>
							<ListItemAvatar>
								<Avatar>
									<AccountCircle />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={userOnline} secondary={`ID: ${id.substring(0, 8)}`} />
							{RemoveContributerButton(contributer)}
						</ListItem>
					)
				})}
			</List>
		</ Container>
	)
}
