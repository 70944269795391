import { Button, Card, CardContent, Dialog, DialogTitle, TextField, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ImageIcon from "@material-ui/icons/PlaylistAdd"

import { addNewList } from "./listsSlice"

export const AddListForm = () => {
	const [name, setName] = useState("")
	const [addRequestStatus, setAddRequestStatus] = useState("idle")
	const addNewListStatus = useSelector((state: any) => state.lists.addNewListStatus)

	const dispatch = useDispatch()

	const onNameChanged = (e: any) => setName(e.target.value)

	const canSave = [name].every(Boolean) && addRequestStatus === "idle"

	const onSaveListClicked = async (e: any) => {
		e.preventDefault()
		if (canSave) {
			try {
				setAddRequestStatus("pending")
				await dispatch(addNewList(name))
				setName("")
			} catch (err) {
				console.error("Failed to create the List: ", err)
			} finally {
				setAddRequestStatus("idle")
			}
		}
	}

	if (addNewListStatus === "rejected") {
		return (
			<Dialog open={true}>
				<DialogTitle id="alert-dialog-title">An Error Occured While Creating The List</DialogTitle>
			</Dialog>
		)
	}

	return (
		<>
			<Card style={{ marginBottom: 12, marginTop: 20 }}>
				<CardContent>
					<Typography variant="h5" component="h2" style={{ paddingBottom: 12 }}>
						<ImageIcon /> Create New List
					</Typography>
					<form onSubmit={onSaveListClicked} noValidate autoComplete="off">
						<TextField
							label="New List Title"
							autoComplete="off"
							fullWidth
							type="text"
							id="ListTitle"
							name="ListTitle"
							placeholder="Home Groceries"
							value={name}
							onChange={onNameChanged}
						/>
						<br />
						<br />
						<Button variant="contained" onClick={onSaveListClicked} disabled={!canSave}>
							Add List
						</Button>
					</form>
				</CardContent>
			</Card>
		</>
	)
}
